/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.0.14
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CustomNotificationPage } from '../model';
// @ts-ignore
import type { ErrorResponse } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { Notification } from '../model';
// @ts-ignore
import type { NotificationIdList } from '../model';
// @ts-ignore
import type { NotificationStatus } from '../model';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Notifications
         * @param {NotificationStatus | null} [notificationStatus] Filter notifications by status.
         * @param {string | null} [name] Filter by notification content
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsApiNotificationsGet: async (notificationStatus?: NotificationStatus | null, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (notificationStatus !== undefined) {
                localVarQueryParameter['notification_status'] = notificationStatus;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
         * @summary Get Unread Notifications Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsStreamApiNotificationsUnreadStreamGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/unread/stream/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {NotificationStatus} notificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationApiNotificationsNotificationIdPatch: async (notificationId: string, notificationStatus: NotificationStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('updateNotificationApiNotificationsNotificationIdPatch', 'notificationId', notificationId)
            // verify required parameter 'notificationStatus' is not null or undefined
            assertParamExists('updateNotificationApiNotificationsNotificationIdPatch', 'notificationStatus', notificationStatus)
            const localVarPath = `/api/notifications/{notification_id}`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (notificationStatus !== undefined) {
                localVarQueryParameter['notification_status'] = notificationStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Notifications
         * @param {NotificationStatus} notificationStatus 
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsApiNotificationsBulkPatch: async (notificationStatus: NotificationStatus, notificationIdList: NotificationIdList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationStatus' is not null or undefined
            assertParamExists('updateNotificationsApiNotificationsBulkPatch', 'notificationStatus', notificationStatus)
            // verify required parameter 'notificationIdList' is not null or undefined
            assertParamExists('updateNotificationsApiNotificationsBulkPatch', 'notificationIdList', notificationIdList)
            const localVarPath = `/api/notifications/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (notificationStatus !== undefined) {
                localVarQueryParameter['notification_status'] = notificationStatus;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationIdList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Notifications
         * @param {NotificationStatus | null} [notificationStatus] Filter notifications by status.
         * @param {string | null} [name] Filter by notification content
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsApiNotificationsGet(notificationStatus?: NotificationStatus | null, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomNotificationPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsApiNotificationsGet(notificationStatus, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.getNotificationsApiNotificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
         * @summary Get Unread Notifications Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.getUnreadNotificationsStreamApiNotificationsUnreadStreamGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {NotificationStatus} notificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationApiNotificationsNotificationIdPatch(notificationId: string, notificationStatus: NotificationStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationApiNotificationsNotificationIdPatch(notificationId, notificationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.updateNotificationApiNotificationsNotificationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Notifications
         * @param {NotificationStatus} notificationStatus 
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationsApiNotificationsBulkPatch(notificationStatus: NotificationStatus, notificationIdList: NotificationIdList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationsApiNotificationsBulkPatch(notificationStatus, notificationIdList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.updateNotificationsApiNotificationsBulkPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Notifications
         * @param {NotificationsApiGetNotificationsApiNotificationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsApiNotificationsGet(requestParameters: NotificationsApiGetNotificationsApiNotificationsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CustomNotificationPage> {
            return localVarFp.getNotificationsApiNotificationsGet(requestParameters.notificationStatus, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
         * @summary Get Unread Notifications Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Notification
         * @param {NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationApiNotificationsNotificationIdPatch(requestParameters: NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.updateNotificationApiNotificationsNotificationIdPatch(requestParameters.notificationId, requestParameters.notificationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Notifications
         * @param {NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsApiNotificationsBulkPatch(requestParameters: NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateNotificationsApiNotificationsBulkPatch(requestParameters.notificationStatus, requestParameters.notificationIdList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNotificationsApiNotificationsGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationsApiNotificationsGetRequest
 */
export interface NotificationsApiGetNotificationsApiNotificationsGetRequest {
    /**
     * Filter notifications by status.
     * @type {NotificationStatus}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly notificationStatus?: NotificationStatus | null

    /**
     * Filter by notification content
     * @type {string}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly size?: number
}

/**
 * Request parameters for updateNotificationApiNotificationsNotificationIdPatch operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest
 */
export interface NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatch
     */
    readonly notificationId: string

    /**
     * 
     * @type {NotificationStatus}
     * @memberof NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatch
     */
    readonly notificationStatus: NotificationStatus
}

/**
 * Request parameters for updateNotificationsApiNotificationsBulkPatch operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest
 */
export interface NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest {
    /**
     * 
     * @type {NotificationStatus}
     * @memberof NotificationsApiUpdateNotificationsApiNotificationsBulkPatch
     */
    readonly notificationStatus: NotificationStatus

    /**
     * 
     * @type {NotificationIdList}
     * @memberof NotificationsApiUpdateNotificationsApiNotificationsBulkPatch
     */
    readonly notificationIdList: NotificationIdList
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Notifications
     * @param {NotificationsApiGetNotificationsApiNotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationsApiNotificationsGet(requestParameters: NotificationsApiGetNotificationsApiNotificationsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationsApiNotificationsGet(requestParameters.notificationStatus, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
     * @summary Get Unread Notifications Stream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Notification
     * @param {NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationApiNotificationsNotificationIdPatch(requestParameters: NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationApiNotificationsNotificationIdPatch(requestParameters.notificationId, requestParameters.notificationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Notifications
     * @param {NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationsApiNotificationsBulkPatch(requestParameters: NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationsApiNotificationsBulkPatch(requestParameters.notificationStatus, requestParameters.notificationIdList, options).then((request) => request(this.axios, this.basePath));
    }
}

