/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.0.14
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OrganizationRoles = {
    Admin: 'admin',
    Member: 'member',
    Internal: 'internal'
} as const;

export type OrganizationRoles = typeof OrganizationRoles[keyof typeof OrganizationRoles];



